import Alpine from '../alpine.js'

Alpine.data('configurationForm', ({ couponOfferPercent, couponDuration }) => ({
  couponOfferPercent,
  couponDuration,
  init () {
    if (this.couponDuration >= 86400) {
      this.couponDurationByUnits = this.couponDuration / 86400
      this.couponDurationUnits = "day"
    } else if (this.couponDuration >= 3600) {
      this.couponDurationByUnits = this.couponDuration / 3600
      this.couponDurationUnits = "hour"
    } else {
      this.couponDurationByUnits = this.couponDuration / 60
      this.couponDurationUnits = "minute"
    }
  },
  calculateCouponDuration () {
    if (this.couponDurationUnits === "day") {
      this.couponDuration = this.couponDurationByUnits * 86400
    } else if (this.couponDurationUnits === "hour") {
      this.couponDuration = this.couponDurationByUnits * 3600
    } else {
      this.couponDuration = this.couponDurationByUnits * 60
    }
  }
}))
