import Alpine from '../alpine.js'
import axios from '../axios.js'

Alpine.data('lead', ({ email, sid }) => ({
  email,
  sid,
  submitting: false,
  submitted: false,

  submit () {
    this.submitting = true
    axios
      .post("/leads", { email: this.email, sid: this.sid })
      .then((response) => { this.submitted = true })
      .catch((error) => { console.log({ error }) })
      .finally(() => { this.submitting = false })
  },

  focusLeadForm () {
    this.$refs.email.scrollIntoView({behavior: 'smooth', block: 'center'})
    setTimeout(() => { this.$refs.email.focus() }, 200)
  }
}))
