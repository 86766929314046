import Alpine from './src/alpine.js'
window.Alpine = Alpine

import './src/components/flash.js'
import './src/components/lead.js'
import './src/components/profile.js'
import './src/components/configuration_form.js'

document.addEventListener('DOMContentLoaded', () => {
  Alpine.start()
})
